import React from 'react'
import Layout from '../components/Layout'
// import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from 'gatsby'
import Video from '../components/Video'

export default function ProjectDetails({ data }) {
    const { html } = data.markdownRemark;
    const { title, categories, videoid } = data.markdownRemark.frontmatter;
    // const image = getImage(data.markdownRemark.frontmatter.img);

    return (
        <Layout>
            <div className='container'>
                <h2>{ title }</h2>
                <h4 className='text-gray'>{ categories }</h4>
                <div>
                    {/* <GatsbyImage image={ image } alt='Presentation Image'/> */}
                    <Video
                        videoSrcURL={ 'https://www.youtube.com/embed/' + videoid }
                    />
                </div>
                <div dangerouslySetInnerHTML={{ __html: html}} />
            </div>
        </Layout>
    )
}

export const query = graphql`
    query ProjectDetails($slug: String) {
        markdownRemark(frontmatter: {slug: {eq: $slug}}) {
            html
            frontmatter {
                title
                categories
                img {
                    childImageSharp {
                        gatsbyImageData(placeholder: BLURRED)
                    }
                }
                videoid
            }
        }
    }
`