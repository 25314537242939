import { graphql, useStaticQuery} from 'gatsby'
import React, { useState } from 'react'
import Navbar from './Navbar'
import '../styles/css/index.css'

export default function Layout({ children }) {
    const data = useStaticQuery(graphql`
    {
        site {
            siteMetadata {
                copyright
            }
        }
    }
    `)
    const { copyright } = data.site.siteMetadata

    // Retrieve the theme that matches the computer default
    const localProp = 'jfs.theme'       // Reference to the local property name
    function getCurrentTheme() {
        // Browser check. Window reference cannot be done server side
        if (typeof window !== "undefined") {
            let theme = window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;   // Check if dark mode is preferred by the operating system
            // Check the local storage for an option to be default
            if (localStorage.getItem(localProp) !== null) {
                theme = localStorage.getItem(localProp);
            }
        return theme;
        }
    }
    
    // Set the initial background styles
    const bg_base = 'container-base';
    const dark = bg_base + ' bg-dark-gray text-white-all';  //Dark-mode setting
    const light = bg_base + ' bg-white';    //Light-mode setting 
    function selectTheme(input) {
        let theme;
        if (input == 'true') {
            theme = dark;
        } else {
            theme = light;
        }
        return theme;
    }
    const [background, setBackground] = useState(selectTheme(getCurrentTheme()));
    // Set the style using the state of the switch
    const handleInput = e => {
        if(e.target.checked) {
            localStorage.setItem(localProp, true);
            setBackground(dark);
        } else {
            localStorage.setItem(localProp, false);
            setBackground(light);
        }
    }
    
    return (
        <div className='bg-secondary'>
            <Navbar handleInput = { handleInput } gct = { getCurrentTheme() }/>
            <div className={ background }>
                { children }
            </div>
            <footer className='container'>
                <p className='text-gray'>{ copyright }</p>
            </footer>
        </div>
    )
}
