import React from 'react'

export default function SwitchDN({ handleInput, gct }) {
    return (
        <div className="">
            <input className="sun-input-style" type="checkbox" id="toggleSunBtn" onChange={handleInput} defaultChecked={gct=='true'}/>
            <div className="sun-toggle-container">
                <label htmlFor="toggleSunBtn" className="sun-label-style"></label>
            <div className="moon-craters"></div>
            </div>
        </div>
    )
}
