import { Link, graphql, useStaticQuery } from 'gatsby'
// import { StaticImage } from "gatsby-plugin-image"
import SwitchDN from "../components/SwitchDN"
import React from 'react'

export default function Navbar({ handleInput, gct }) {
    const data = useStaticQuery(graphql`
    {
        site {
            siteMetadata {
                title
            }
        }
    }
    `)

    const{ title } = data.site.siteMetadata
    return (
        <div className='navbar-primary'>
            <nav className='container'> 
                <h1 className='text-white p-1'>{ title }</h1>
                <div className="row gapTB-2 justify-flex-end">
                    <Link to="/" className='text-white'>Home</Link>
                    <Link to="/projects" className='text-white'>Our Projects</Link>
                    <Link to="/about" className='text-white'>About</Link>
                    <Link to="/contact" className='text-white'>Contact-Us</Link>
                    <SwitchDN handleInput = {handleInput} gct = {gct}/>
                </div>
            </nav>
        </div>
    )
}
